import { Link } from 'react-router-dom';

export function Tabs({selected}) {
  const selectedClass = 'border-t border-b bg-gray-900 text-white border-gray-900 hover:bg-gray-800';
  const unselectedClass = 'rounded border bg-white text-gray-800 border-gray-200 hover:bg-gray-100';
  return (
    <div className="flex w-full md:max-w-xl mx-4 rounded shadow mt-6">
      <Link
        to={'/'}
        aria-current={selected === 'calendar' ? 'page' : 'false'}
        className={`w-full flex justify-center font-medium px-5 py-2 ${selected === 'calendar' ? selectedClass : unselectedClass}`}
      >
        Calendario
      </Link>

      <Link
        to={'/list'}
        aria-current={selected === 'list' ? 'page' : 'false'}
        className={`w-full flex justify-center font-medium px-5 py-2 ${selected === 'list' ? selectedClass : unselectedClass}`}
      >
        Listado
      </Link>
    </div>
  );
}
