import { Outlet } from 'react-router-dom';
import { Footer } from '../components/Footer';
import { Header } from '../components/Header';
import { useEffect, useState } from 'react';
import { SESSION_URL } from '../constants';
import { Loader } from '../components/Loading';

export function PageLayout() {
  const [isLoadingSession, setLoadingSession] = useState(true);
  const [sessionError, setSessionError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(SESSION_URL, { credentials: 'include' });
      if (!response.ok) {
        setSessionError(true);
      }
      setLoadingSession(false)
    };
    fetchData();
  }, []);

  return (
    <div className="w-full flex flex-col min-h-screen justify-between">
      <Header />
      <div style={{maxWidth: '1220px'}} className="lg:container md:mx-auto flex flex-col flex-1">
        <section className='lg:w-4/6 pt-1 pb-3 mx-auto w-full'>
          {isLoadingSession ? <Loader /> : (sessionError ? 'Error' : <Outlet />)}
        </section>
      </div>
      <Footer />
    </div>
  );
}

