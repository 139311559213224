export function Loading() {
  return (
    <div className='p-5'>
      <img src='/loading-blocks.svg' alt='loading' className='w-24 h-24' />
    </div>
  )
}

export function LoadingTweet() {
  return (
    <div className='px-5 flex flex-col w-full justify-center items-center gap-2'>
      <span className='text-gray-700'>Cargando tweet...</span>
      <img src='/loading-blocks.svg' alt='loading' className='w-8 h-8' />
    </div>
  )
}

export function SmallLoading() {
  return (
    <div className='p-2'>
      <img src='/loading-blocks.svg' alt='loading' className='w-10 h-10' />
    </div>
  )
}

export function Loader() {
  return (
    <div className="w-full flex justify-center items-center">
      <Loading />
    </div>
  );
}