export function Tags({ tags }) {
  return (
    <>
      {tags.map((tag, idx) => (
        <span
          key={idx}
          className="mt-2 lg:mt-0 inline-block bg-gray-200 rounded-lg px-3 py-1 text-sm  text-gray-700 mr-2"
        >
          <a className="hover:underline text-nowrap" href={`/list?tags=${encodeURIComponent(tag)}`}>{tag}</a>
        </span>
      ))}
    </>
  );
}
