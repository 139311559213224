import { SortableList } from "../sortable/SortableList";
import { DragHandle, SortableItem } from "../sortable/SortableItem";

export function AdminMediaForm({
  handleChange,
  handleAddMedia,
  handleRemoveMedia,
  mediaList,
  dataKey,
  onSortChange,
}) {
  return (
    <>
      <SortableList
        items={mediaList}
        onChange={onSortChange}
        renderItem={(media, index) => (
          <SortableItem id={media.id}>
            <DragHandle />
            <div key={index} className="flex flex-col flex-1 ">
              <div className="flex items-start space-x-4 ">
                <select
                  name="type"
                  value={media.type}
                  onChange={(e) => handleChange(index, dataKey, e)}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-1/2 shadow-sm sm:text-sm border border-gray-300 h-12 px-2 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent bg-white"
                >
                  <option value="image">Image</option>
                  <option value="tweet">Tweet</option>
                </select>
                <input
                  type="text"
                  name="url"
                  value={media.url}
                  onChange={(e) => handleChange(index, dataKey, e)}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-2 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
                  placeholder={media.type === "tweet" ? "Tweet ID" : "URL"}
                />
                <input
                  type="text"
                  name="source"
                  value={media.source}
                  onChange={(e) => handleChange(index, dataKey, e)}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-2 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
                  placeholder={"Link a fuente"}
                />
              </div>
              <div className="flex mt-2 items-start space-x-4 ">
                <textarea
                  name="preDescription"
                  placeholder="Descripción previa"
                  value={media.preDescription || ""}
                  onChange={(e) => handleChange(index, dataKey, e)}
                  rows={3}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-32 px-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
                />
                <textarea
                  name="description"
                  placeholder="Descripción"
                  value={media.description}
                  onChange={(e) => handleChange(index, dataKey, e)}
                  rows={3}
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-32 px-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
                />
              </div>
              <button type='button' className="text-blue-500 mt-2 w-full text-right" onClick={() => handleRemoveMedia(index, dataKey)}>Eliminar</button>
            </div>
          </SortableItem>
        )}
      />
      <button
        type="button"
        onClick={() => handleAddMedia(dataKey)}
        className="mt-4 inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Agregar Más
      </button>
    </>
  );
}
