import { differenceInDays, format, getUnixTime } from 'date-fns';

export function formatDate(dateString) {
  const timestamp = Number(dateString) * 1000;
  const formattedDate = format(new Date(timestamp), 'dd/MM/yyyy');
  return formattedDate;
};

export function getAdminHeaders() {
  const token = localStorage.getItem('token');

  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
}

export function getDaysFrom(dateString) {
  const timestamp = Number(dateString) * 1000;
  const date = new Date(timestamp);
  return differenceInDays(new Date(), date);
}

export function dateToTimestamp(date) {
  if (!date) return null;
  const [day, month, year] = date.split('/').map(Number);
  return getUnixTime(new Date(year, month - 1, day));
}

export function getDayMonthLabelInSpanish(timestamp) {
  const date = formatDate(timestamp);
  const splittedDate = date.split('/');
  const day = splittedDate[0];
  const month = splittedDate[1] - 1;

  const monthNames = [
    "Enero", "Febrero", "Marzo",
    "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre",
    "Noviembre", "Diciembre"
  ];

  return `${day} de ${monthNames[month]}, ${splittedDate[2]}`;
}
