import React, { useState } from "react";
import { ADMIN_OPERATIONS_URL } from "../../constants";
import toast from "react-hot-toast";
import { dateToTimestamp, formatDate, getAdminHeaders } from "../../utils";
import { AdminMediaForm } from "./AdminMediaForm";

const DEFAULT_MEDIA_TYPE = "image";
const emptyMedia = {
  type: DEFAULT_MEDIA_TYPE,
  url: "",
  preDescription: "",
  description: "",
  source: "",
};

const tagsToArray = (tags) => tags.split(",").map((tag) => tag.trim());
const tagsToString = (tags) => tags.join(", ");

const addIndexAsId = (items) =>
  items.map((i, index) => ({
    ...i,
    id: index + 1,
  }));

function getOperationState(operation) {
  if (!operation) {
    return {
      title: "",
      description: "",
      thumbnail: "",
      operationDescription: "",
      realityDescription: "",
      operationMedia: addIndexAsId([{ ...emptyMedia }]),
      realityMedia: addIndexAsId([{ ...emptyMedia }]),
      sources: [{ link: "", description: "" }],
      tags: "",
      isVisible: false,
      startDate: '',
      endDate: '',
    };
  }

  if (operation.isVisible === undefined)
    operation.isVisible = true;

  return {
    title: operation.title,
    thumbnail: operation.thumbnail || "",
    description: operation.description,
    operationDescription: operation.operationDescription || "",
    realityDescription: operation.realityDescription || "",
    operationMedia: addIndexAsId(operation.operationMedia),
    realityMedia: addIndexAsId(operation.realityMedia),
    sources: operation.sources,
    tags: tagsToString(operation.tags || []).trim(),
    isVisible: operation.isVisible,
    startDate: operation.startDate && formatDate(operation.startDate),
    endDate: operation.endDate && formatDate(operation.endDate),
  };
}

export function AdminOperationForm({ operation }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(getOperationState(operation));

  const onSortChange = (key) => (value) => {
    const newFormData = { ...formData };
    newFormData[key] = value;
    setFormData(newFormData);
  };

  const handleChange = (index, field, value) => {
    const newFormData = { ...formData };
    if (Array.isArray(newFormData[field])) {
      newFormData[field][index][value.target.name] = value.target.value;
    } else {
      newFormData[field] = value.target.value;
    }
    setFormData(newFormData);
  };

  const handleCheckboxChange = (field) => {
    const newFormData = { ...formData };
    newFormData[field] = !newFormData[field];
    setFormData(newFormData);
  };

  const handleAddMedia = (field) => {
    const newFormData = { ...formData };
    newFormData[field].push({ ...emptyMedia });
    newFormData[field] = addIndexAsId(newFormData[field]);
    setFormData(newFormData);
  };

  const handleRemoveMedia = (index, dataKey) => {
    const newFormData = { ...formData };
    newFormData[dataKey] = newFormData[dataKey].filter((_, i) => i !== index);
    newFormData[dataKey] = addIndexAsId(newFormData[dataKey]);
    setFormData(newFormData);
  }

  const handleAddSource = () => {
    const newFormData = { ...formData };
    newFormData.sources.push({ link: "", description: "" });
    setFormData(newFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    toast.promise(submitForm(), {
      loading: "Saving...",
      success: <b>Operacion Guardada!</b>,
      error: <b>Hubo un error.</b>,
    });
  };
  const submitForm = async (e) => {
    try {
      setLoading(true);

      const url = operation
        ? `${ADMIN_OPERATIONS_URL}/${operation.id}`
        : ADMIN_OPERATIONS_URL;
      const data = { ...formData };
      data.tags = tagsToArray(data.tags);

      data.startDate = dateToTimestamp(data.startDate);
      console.log('data.endDate',data.endDate, !data.endDate, data.startDate);
      // data.endDate = !data.endDate ? data.startDate : dateToTimestamp(data.endDate);
      data.endDate = dateToTimestamp(data.endDate);

      const body = JSON.stringify(data);
      const response = await fetch(url, {
        method: operation ? "PUT" : "POST",
        headers: getAdminHeaders(),
        body,
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error("Failed to submit data");
      }

      if (!operation) {
        setFormData({
          title: "",
          description: "",
          operationMedia: [{ ...emptyMedia }],
          realityMedia: [{ ...emptyMedia }],
          sources: [{ link: "", description: "" }],
          tags: "",
        });
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setLoading(false);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="space-y-2">
        <div className="flex justify-end">
          <button
            disabled={loading}
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? "Procesando.." : "Guardar"}
          </button>
        </div>
        <div className="mb-4">
          <label htmlFor="title" className="block text-md font-medium ">
            Título
          </label>
          <input
            type="text"
            name="title"
            id="title"
            value={formData.title}
            onChange={(e) => handleChange(null, "title", e)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
          />
        </div>
        <div className="mb-4 flex">
          <input
            checked={formData.isVisible}
            id="isVisible"
            name="isVisible"
            type="checkbox"
            onChange={(e) => handleCheckboxChange("isVisible")}
            className={`w-4 h-4`} />
          <label htmlFor="isVisible"
            className="ms-2 text-sm font-medium">
            Visible
          </label>
        </div>
        <div className="mb-4 items-center flex gap-1">
          <label htmlFor="startDate" className="block text-md font-medium ">
            Desde/Hasta
          </label>
          <input
            type="text"
            name="startDate"
            id="startDate"
            value={formData.startDate}
            onChange={(e) => handleChange(null, "startDate", e)}
            className="w-2/6 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
            placeholder='dd/mm/yyyy'
          />
          -
          <input
            type="text"
            name="endDate"
            id="endDate"
            value={formData.endDate}
            onChange={(e) => handleChange(null, "endDate", e)}
            placeholder='dd/mm/yyyy'
            className="w-2/6 mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-md font-medium ">
            Descripción
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={(e) => handleChange(null, "description", e)}
            rows={3}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-32 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="thumbnail" className="block text-md font-medium ">
            Imagen de portada (Thumbnail)
          </label>
          <input
            type="text"
            name="thumbnail"
            id="thumbnail"
            value={formData.thumbnail}
            onChange={(e) => handleChange(null, "thumbnail", e)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg border-b-2 border-gray-800 font-medium ">Operetas</label>
          <div className="mb-4">
            <label htmlFor="operationDescription" className="block text-md font-medium ">
              Intro
            </label>
            <textarea
              id="operationDescription"
              name="operationDescription"
              value={formData.operationDescription}
              onChange={(e) => handleChange(null, "operationDescription", e)}
              rows={3}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-32 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
            />
          </div>
          <AdminMediaForm
            handleChange={handleChange}
            handleAddMedia={handleAddMedia}
            handleRemoveMedia={handleRemoveMedia}
            mediaList={formData.operationMedia}
            dataKey="operationMedia"
            onSortChange={onSortChange("operationMedia")}
          />
        </div>
        <div className="mb-4">
          <label className="block text-lg font-medium border-gray-800 border-b-2 ">Realidad</label>
          <div className="mb-4">
            <label htmlFor="realityDescription" className="block text-md font-medium ">
              Intro
            </label>
            <textarea
              id="realityDescription"
              name="realityDescription"
              value={formData.realityDescription}
              onChange={(e) => handleChange(null, "realityDescription", e)}
              rows={3}
              className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-32 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
            />
          </div>
          <AdminMediaForm
            handleChange={handleChange}
            handleAddMedia={handleAddMedia}
            handleRemoveMedia={handleRemoveMedia}
            mediaList={formData.realityMedia}
            dataKey="realityMedia"
            onSortChange={onSortChange("realityMedia")}
          />
        </div>
        <div className="mb-4">
          <label className="block text-md font-medium ">Fuentes</label>
          {formData.sources.map((source, index) => (
            <div key={index} className="flex items-center space-x-4 mb-2">
              <input
                type="text"
                name="link"
                value={source.link}
                onChange={(e) => handleChange(index, "sources", e)}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
                placeholder="Link"
              />
              <input
                type="text"
                name="description"
                value={source.description}
                onChange={(e) => handleChange(index, "sources", e)}
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
                placeholder="Description"
              />
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddSource}
            className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Agregar Más
          </button>
        </div>
        <div className="mb-4">
          <label htmlFor="tags" className="block text-md font-medium ">
            Tags (separados por coma)
          </label>
          <input
            autoComplete="off"
            type="text"
            name="tags"
            id="tags"
            value={formData.tags}
            onChange={(e) => handleChange(null, "tags", e)}
            className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 h-12 px-4 py-2 text-lg text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 ring-transparent"
          />
        </div>
        <div className="flex justify-end">
          <button
            disabled={loading}
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {loading ? "Procesando.." : "Guardar"}
          </button>
        </div>
      </form>
    </>
  );
}
