export function Footer() {
  return (
    <footer className="bg-white">
      <div>
        <div className="mx-auto max-w-screen-xl px-4 pb-6 pt-16 sm:px-6 lg:px-8 lg:pt-24">
          <div className="flex align-between justify-between">
            
            <div className='flex-1 flex align-end'>
              <ul className="mt-8 flex align-end justify-center gap-6 sm:justify-start md:gap-8">
                <li className="flex align-end">
                  <a
                    href="https://twitter.com/operetascom"
                    rel="noreferrer"
                    target="_blank"
                    className="transition hover:text-teal-700/75"
                  >
                    <span className="sr-only">Twitter</span>
                    <svg className="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                      <path
                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
                      />
                    </svg>
                  </a>
                </li>

              </ul>
            </div>

            <div>
              <p className="mt-6 max-w-md text-center leading-relaxed text-gray-500 sm:max-w-xs sm:text-left">
                Solo un sitio que recopila operetas para que no se pierdan en el éter de la red, y especialmente para que no sean olvidadas.
                Por cualquier sugerencia o correción (somos humanos, che) no dudes en contactarnos en <a href="mailto:operetascom@gmail.com" className="color-link">operetascom@gmail.com</a>.
              </p>
            </div>
          </div>
          {/* Surveillance Eye by Agus Purwanto from <a href="https://thenounproject.com/browse/icons/term/surveillance-eye/" target="_blank" title="Surveillance Eye Icons">Noun Project</a> (CC BY 3.0) */}
          <div className="mt-12 border-t border-gray-100 pt-6">
            <div className="text-center sm:flex sm:justify-between sm:text-left">
              <p className="text-sm text-gray-500">
                <span className="block sm:inline">Todos los derechos reservados.</span>

                <a
                  href="/tos"
                  className="inline-block underline transition hover:text-gray-200"
                >
                  Términos & Condiciones
                </a>

                <span>&middot;</span>

                <a
                  className="inline-block underline transition hover:text-gray-200"
                  href="/privacy"
                >
                  Política de Privacidad
                </a>
              </p>

              <p className="mt-4 text-sm text-gray-500 sm:order-first sm:mt-0">&copy; 2024 Operetas</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}