import { useEffect } from 'react';
import debounce from 'lodash.debounce';

const SCROLL_THRESHOLD = 50; // adjust this value based on your needs

const useInfiniteScroll = (loading, fetchMoreData) => {
  useEffect(() => {
    const handleScroll = debounce(() => {
      if (loading) return;

      const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
      const bottomPosition = document.documentElement.offsetHeight - SCROLL_THRESHOLD;

      if (scrollPosition >= bottomPosition) {
        fetchMoreData();
      }
    }, 100); // debounce delay in milliseconds, adjust based on performance

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [loading, fetchMoreData]);
};

export { useInfiniteScroll };
