import { useState } from "react";
import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate, useParams } from "react-router-dom";
import { OPERATIONS_URL, SEO_KEYWORDS } from "../../constants";
import { formatDate } from "../../utils";
import { CardMedia } from "../Media/CardMedia";
import { Tags } from "../Tags/Tags";
import { Linkify } from "../Linkify";
import { Loader } from "../Loading";
import { Helmet } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import { Recommendations } from './Recommendations';

export function DetailCard() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `${OPERATIONS_URL}/${id}`;
      const response = await fetch(url, { credentials: 'include' });

      if (response.status !== 200) {
        navigate("/");
        return;
      }

      const parsed = await response.json();
      setData(parsed);
      setLoading(false);
    };

    fetchData();
  }, [id, navigate]);

  if (loading) return <Loader />;

  return (
    <div className="px-4 mt-2 relative w-full flex gap-1">
      <Helmet>
        <title>{`Operetas.com - ${data.title}`}</title>
        <meta name="description" content={data.description} />
        <meta name="keywords" content={SEO_KEYWORDS} />

        <meta property="og:title" content={`Operetas.com - ${data.title}`} />
        <meta property="og:description" content={data.description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://www.operetas.com/opereta/${id}`} />
        <meta property="og:image" content={data.thumbnail} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Operetas.com - ${data.title}`} />
        <meta name="twitter:description" content={data.description} />
        <meta name="twitter:image" content={data.thumbnail} />
      </Helmet>
      <div className="w-full flex flex-col justify-center items-center">
        <div className="text-color-4 text-right w-full">
          {/* Subido el {formatDate(data.updatedAt)} */}
          Sucedió alrededor del {formatDate(data.startDate)}
        </div>
        <h2 className="w-full font-semibold text-4xl text-color-4 text-left">
          {data.title}
        </h2>
        <div className="flex flex-wrap w-full justify-start my-2">
          {data.tags && <Tags tags={data.tags} />}
        </div>
        <p className="w-full pt-2 text-lg text-color-4 text-base">
          <Linkify>{data.description}</Linkify>
        </p>

        <h3 className="border-b-2	border-gray-800 mt-4 mb-1 w-full text-left text-2xl font-semibold text-gray-800">
          Opereta
        </h3>
        {data.operationDescription &&
          <p className="w-full text-lg text-color-4 text-base">
            <Linkify>{data.operationDescription}</Linkify>
          </p>
        }
        <div className="w-full">
          <CardMedia media={data.operationMedia} />
        </div>

        <h3 className="border-b-2	border-gray-800 mt-4 mb-1 w-full text-left text-2xl font-semibold text-gray-800">
          Realidad
        </h3>
        {data.realityDescription &&
          <p className="w-full pt-2 value-text text-lg text-color-4 text-base">
            <Linkify>{data.realityDescription}</Linkify>
          </p>
        }
        <div className="w-full">
          <CardMedia media={data.realityMedia} />
        </div>

        <div className='mt-4'>
          <Recommendations id={data.id} />
        </div>
      </div>
    </div>
  );
}
