export function MediaFooter({ source, align }) {
  return (<div className="mt-2 flex justify-end">
    <ul className="w-full border-gray-800">
      {source && (
        <li className={`small-text mb-2 text-${align} text-truncate`}>
          Fuente:&nbsp;
          <a
            target="_blank"
            href={source}
            className="underline color-link "
            rel="noreferrer"
          >
            {source}
          </a>
        </li>
      )}
    </ul>
  </div>)
}
