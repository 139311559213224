import { useLocation, useNavigate } from 'react-router-dom';
import { useTags } from '../../hooks/useTags';
import { MultiSelectDropdown } from '../MultiSelectDropdown';

export function TagsFilter() {
  const { tags, loading } = useTags();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const handleTagsChange = (selectedTags) => {
    if (selectedTags.length === 0) {
      queryParams.delete('tags');
      const search = `?${queryParams.toString()}`;
      navigate({ search });
      return;
    }
    const tags = selectedTags.join(',');
    queryParams.set('tags', tags);
    const search = `?${queryParams.toString()}`;
    navigate({ search });
  }

  return (
    <>
    <div className="flex flex-wrap">
      <MultiSelectDropdown
        formFieldName={"tags"}
        options={tags}
        loading={loading}
        onChange={handleTagsChange}
        prompt="Filtrar por Tags"
        preSelected={queryParams.get('tags')?.split(',') || []}
        />
    </div>
    {queryParams.get('tags') && <SelectedTags tags={queryParams.get('tags').split(',')} />}
    </>
  );
}

function SelectedTags({ tags }) {
  return (
    <div className="flex w-full justify-start flex-wrap">
      Tags Seleccionados:&nbsp; 
      {tags.map((tag) => (
        <span key={tag} className="bg-blue-500 text-white rounded-full px-2 py-1 text-xs mr-2">{tag}</span>
      ))}
    </div>
  );
}