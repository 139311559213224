import { Link } from "react-router-dom";
import "./ListCard.css";
import { Tags } from "../Tags/Tags";
import { Linkify } from "../Linkify";
import { getDayMonthLabelInSpanish } from "../../utils";

export function ListCardAlt({ data }) {
  const { slug, title, description, thumbnail, tags, startDate } = data;

  return (
    <div className="flex-1 card border-gray-800 pb-4 pt-4">
      <div className="flex flex-col">
        <div className="md:flex md:gap-1">
          <div className={`flex flex-col gap-1 ${thumbnail && "md:w-4/6"}`}>
            <div className="font-bold text-color-2 text-xl">
              <Link target="_blank" className="hover:underline" to={`/opereta/${slug}`}>
                {title}
              </Link>
            </div>
            <Link target="_blank" to={`/opereta/${slug}`}>
              <p className="text-color-1 text-base cursor-pointer">
                <Linkify>{description}</Linkify>
              </p>
            </Link>
          </div>
          {thumbnail && (
            <div className="flex-0 flex items-center w-full mt-2 md:mt-0 md:w-2/6">
              <Link target="_blank" to={`/opereta/${slug}`}>
                <img alt="thumbnail" src={thumbnail} />
              </Link>
            </div>
          )}
        </div>

        <div className="flex flex-col lg:flex-row justify-between mt-5">
          <div className="flex items-end justify-start">
            {/* <DaysFrom createdAt={createdAt} /> */}
            <div className="flex items-end justify-start">
              {getDayMonthLabelInSpanish(startDate)}
            </div>
          </div>
          <div className="flex flex-wrap justify-start lg:justify-end">
            {tags && <Tags tags={tags} />}
          </div>
        </div>
      </div>
    </div>
  );
}

// function DaysFrom({ createdAt }) {
//   const daysFrom = getDaysFrom(createdAt);
//   if (daysFrom === 0)
//     return <div className="flex items-end justify-start">Hoy</div>;
//   if (daysFrom === 1)
//     return <div className="flex items-end justify-start">Ayer</div>;
//   return (
//     <div className="flex items-end justify-start">
//       Hace {daysFrom} d&iacute;as
//     </div>
//   );
// }
