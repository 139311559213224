import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ADMIN_OPERATIONS_URL } from '../../constants';
import { getAdminHeaders } from '../../utils';
import toast from 'react-hot-toast';
import { AdminOperationForm } from './AdminOperationForm';
import { Helmet } from 'react-helmet-async';

export function AdminEditOperation() {
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [operation, setOperation] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const headers = getAdminHeaders();
        const url = `${ADMIN_OPERATIONS_URL}/${id}`;
        const response = await fetch(url, { headers, credentials: 'include' });
        const data = await response.json();
        setOperation(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error('Error cargando Opereta');
      }
    };
    fetchData();
  }, [id]);

  if (loading) {
    return <p>Cargando...</p>;
  }

  return (
    <div className="container mx-auto">
      <Helmet>
        <title>{`Admin - ${(operation && operation.title) || ''}`}</title>
      </Helmet>
      <div className="w-4/6 mx-auto">
        <h1 className="text-2xl font-bold mt-4">Editar Opereta</h1>
        { operation && <Link target='_blank' className="block cursor-pointer text-blue-500 hover:text-blue-700 underline mb-2" to={`/opereta/${operation.slug}`}>Ver</Link> }
        {operation && <AdminOperationForm operation={operation} />}
      </div>
    </div>
  )
}
