import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { SEO_DESCRIPTION, SEO_KEYWORDS } from '../constants';

export function PrivacyPolicy() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + (window.location.search || '') });
  }, []);

  return (
    <div className="bg-gray-100 mt-6 p-8 rounded-lg">
      <Helmet>
        <title>{'Operetas.com - Términos y condiciones'}</title>
        <meta name="description" content={SEO_DESCRIPTION} />
        <meta name="keywords" content={SEO_KEYWORDS} />

        <meta property="og:title" content={'Operetas.com - Términos y condiciones'} />
        <meta property="og:description" content={SEO_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.operetas.com" />
        <meta property="og:image" content="https://operetas.com/meta-logo.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={'Operetas.com - Términos y condiciones'} />
        <meta name="twitter:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:image" content="https://operetas.com/meta-logo.png" />
      </Helmet>
      <Link to="/" className='color-link'>Volver</Link>
      <h2 className="text-2xl font-bold mb-4">Política de Privacidad</h2>
      <p className="mb-4">
        Nuestra prioridad es mantener la privacidad y seguridad de la información personal que recopilamos de nuestros usuarios. Esta Política de Privacidad describe cómo recopilamos, utilizamos y protegemos tu información cuando visitas nuestro sitio web.
      </p>
      <ol className="list-decimal ml-6 mb-4">
        <li className="mb-2">
          <span className="font-bold">Información Recopilada:</span> Recopilamos información personal que proporcionas voluntariamente al utilizar nuestro sitio web, como tu nombre, dirección de correo electrónico y cualquier otra información que decidas proporcionar. Además, podemos recopilar información no personal, como datos de uso y cookies.
        </li>
        <li className="mb-2">
          <span className="font-bold">Uso de la Información:</span> Utilizamos la información que recopilamos para proporcionar y mejorar nuestros servicios, personalizar tu experiencia en el sitio web, comunicarnos contigo y responder a tus consultas y solicitudes.
        </li>
        <li className="mb-2">
          <span className="font-bold">Divulgación de la Información:</span> No vendemos, intercambiamos ni transferimos tu información personal a terceros sin tu consentimiento, excepto cuando sea necesario para cumplir con la ley, proteger nuestros derechos o cumplir con nuestras obligaciones legales.
        </li>
        <li className="mb-2">
          <span className="font-bold">Seguridad de la Información:</span> Implementamos medidas de seguridad para proteger tu información personal contra el acceso no autorizado, la alteración, la divulgación o la destrucción.
        </li>
        <li className="mb-2">
          <span className="font-bold">Enlaces a Otros Sitios:</span> Nuestro sitio web puede contener enlaces a otros sitios web que no están controlados por nosotros. No somos responsables de las prácticas de privacidad de estos sitios web de terceros.
        </li>
        <li className="mb-2">
          <span className="font-bold">Cambios en la Política de Privacidad:</span> Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Se te notificará sobre cualquier cambio mediante la publicación de la Política de Privacidad actualizada en este sitio web.
        </li>
      </ol>
      <p className="mt-4">Al utilizar nuestro sitio web, aceptas esta Política de Privacidad. Si tienes alguna pregunta sobre esta Política de Privacidad, contáctanos en <a href="mailto:operetasarg@gmail.com" className="color-link">operetasarg@gmail.com</a>.</p>
    </div>
  )
}
