import React from 'react';
import { AdminOperationForm } from './AdminOperationForm';
import { Helmet } from 'react-helmet-async';

export function AdminCreateOperation() {
  return (
    <div className="container mx-auto">
      <Helmet>
        <title>{`Admin - Nueva Opereta`}</title>
      </Helmet>
      <div className="w-4/6 mx-auto">
        <h1 className="text-2xl font-bold my-4">Crear Opereta</h1>
        <AdminOperationForm />
      </div>
    </div>
  );
}
