import { useCallback, useEffect, useState } from "react";
import { OPERATIONS_URL, SEO_DESCRIPTION, SEO_KEYWORDS } from "../constants";
import { ListCardAlt } from "../components/ListCardAlt/ListCard";
import { Loader } from "../components/Loading";
import { useLocation } from "react-router-dom";
import { TagsFilter } from "../components/Tags/TagsFilter";
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import { useInfiniteScroll } from '../hooks/useInfiniteScroll';
import { Tabs } from "../components/Tabs";
// import { useGroupedOperations } from '../hooks/useGroupedOperations';

function getUrlWithIndexAndTags(index, tags) {
  const url = new URL(OPERATIONS_URL);
  if (index) {
    url.searchParams.append("lastCreatedAt", index);
  }
  if (tags) {
    url.searchParams.append("tags", tags);
  }
  return url.toString();
}

function getUrlWithTags(tags) {
  const url = new URL(OPERATIONS_URL);
  if (tags) {
    url.searchParams.append("tags", tags);
  }
  return url.toString();
}

const META_TITLE = "Operetas.com - Archivo de operetas políticas orwellianas";

export function OperetasList() {
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [index, setIndex] = useState(null);
  const [noMoreResults, setNoMoreResults] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedTags = queryParams.get("tags");
  // const { groupedOperations, loading: loadingGrouped } = useGroupedOperations();
  // console.log('groupedOperations', groupedOperations);
  const fetchMoreData = useCallback(async () => {
    if (noMoreResults) return;
    const url = getUrlWithIndexAndTags(index, selectedTags);
    const response = await fetch(url, { credentials: 'include' });
    const data = await response.json();
    if (data.length > 0) {
      const lastCreatedAt = data[data.length - 1].startDate;
      setIndex(lastCreatedAt);
      setRecords((r) => [...r, ...data]);
      return;
    }

    setNoMoreResults(true);
  }, [index, selectedTags, noMoreResults]);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + (window.location.search || '') });
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = getUrlWithTags(selectedTags);
      const response = await fetch(url, { credentials: 'include' });
      const data = await response.json();
      if (data.length > 0) {
        const lastCreatedAt = data[data.length - 1].startDate;
        setIndex(lastCreatedAt);
        setRecords(data);
        setLoading(false);
        return;
      }

      setLoading(false);
      setNoMoreResults(true);
    };
    fetchData();
  }, [selectedTags]);

  useInfiniteScroll(loading, fetchMoreData);

  return (
    <div className="px-2 text-gray-600 body-font flex flex-col items-center">
      <Helmet>
        <title>{META_TITLE}</title>
        <meta name="description" content={SEO_DESCRIPTION} />
        <meta name="keywords" content={SEO_KEYWORDS} />

        <meta property="og:title" content={META_TITLE} />
        <meta property="og:description" content={SEO_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.operetas.com/list" />
        <meta property="og:image" content="https://operetas.com/meta-logo.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={META_TITLE} />
        <meta name="twitter:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:image" content="https://operetas.com/meta-logo.png" />
      </Helmet>
      <Tabs selected={'list'} />
      <div className="flex flex-col items-center justify-center mx-auto mt-4">
        {!loading && records.length === 0 && (
          <h1 className="text-xl mt-8 text-white">No hay operetas para ver</h1>
        )}
        <div className="w-full flex flex-col items-end sticky bg-white p-2" style={{top: '69px', zIndex: '1'}}>
          <TagsFilter />
        </div>
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full">
            {records.map((record, idx) => (
              <ListCardAlt key={idx} data={record} />
            ))}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}
