import { TwitterTweetEmbed } from "react-twitter-embed";

import { MediaFooter } from "./MediaFooter";
import { LoadingTweet } from "../Loading";
import { Linkify } from "../Linkify";

export function MediaTweet({ media }) {
  const { url, preDescription, description, source } = media;

  return (
    <div className="media-card">
      <div className="flex flex-col">
        {preDescription && (
          <p className="mb-2 pt-1 text-lg text-color-4 text-base">
            <Linkify>{preDescription}</Linkify>
          </p>
        )}
        <TwitterTweetEmbed
          placeholder={<LoadingTweet />}
          options={{ conversation: "none" }}
          tweetId={url}
        />
        {description && (
          <p className="mt-2 pt-2 text-lg text-color-4 text-base">
            <Linkify>{description}</Linkify>
          </p>
        )}
        <MediaFooter source={source} align="center" />
      </div>
    </div>
  );
}
