import React, { useState, useEffect } from "react";
import { ADMIN_OPERATIONS_URL, ADMIN_PATH, GO_TO_AFTER_401 } from "../../constants";
import { formatDate, getAdminHeaders } from "../../utils";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Loader } from "../Loading";

export function AdminListOperations() {
  const [operations, setOperations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [noMorePages, setNoMorePages] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const getShortDescription = (description) => {
    return description.length > 100
      ? `${description.substring(0, 100)}...`
      : description;
  };

  const deleteOperation = async (id) => {
    const headers = getAdminHeaders();
    const response = await fetch(`${ADMIN_OPERATIONS_URL}/${id}`, {
      method: "DELETE",
      headers,
      credentials: 'include'
    });

    if (response.status === 204) {
      setOperations((ops) => ops.filter((operation) => operation.id !== id));
    } else {
      throw new Error("Failed to delete operation");
    }
  };

  const confirmDelete = async (id) => {
    if (window.confirm("¿Estás seguro de que quieres borrar esta opereta?")) {
      toast.promise(deleteOperation(id), {
        loading: "Borrando...",
        success: <b>Operacion Eliminada!</b>,
        error: <b>Hubo un error.</b>,
      });
    }
  };

  const getLastCreatedAt = () => {
    if (currentPage > 1 && operations.length > 0) {
      return operations[operations.length - 1].createdAt;
    }
    return null;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const lastCreatedAt = getLastCreatedAt();
        const headers = getAdminHeaders();
        const url = lastCreatedAt
          ? `${ADMIN_OPERATIONS_URL}?lastCreatedAt=${lastCreatedAt}`
          : ADMIN_OPERATIONS_URL;

        const response = await fetch(url, { headers, credentials: 'include' });
        if (response.status === 401) {
          navigate(GO_TO_AFTER_401);
          return;
        }
        const data = await response.json();
        setLoading(false);
        if (data.length === 0) {
          setNoMorePages(true);
          return;
        }
        setOperations(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching operations:", error);
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, navigate]);

  return (
    <div className="container flex-col items-center justify-center">
      <h2 className="pt-4 mb-4 text-5xl  text-right">Operetas</h2>
      {loading ? (
        <Loader />
      ) : (
        <>
          <table className="min-w-full divide-y divide-gray-700 w-full">
            <thead className="bg-gray-800 sticky top-0 ">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Visible
                </th>
                <th
                  style={{ width: "20%" }}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                >
                  Titulo
                </th>
                <th
                  style={{ width: "50%" }}
                  className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"
                >
                  Descripci&oacute;n
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Creada
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                  Editada
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-300 uppercase tracking-wider"></th>
              </tr>
            </thead>
            <tbody className=" divide-y divide-gray-700">
              {operations.map((operation) => (
                <tr key={operation.id}>
                  <td className="px-1 py-2 text-sm text-center align-baseline">{operation.isVisible ? 'Si' : 'No' }</td>
                  <td className="px-1 py-2 text-sm align-baseline">
                    <Link
                      className="cursor-pointer text-blue-500 hover:text-blue-700 underline"
                      to={`/${ADMIN_PATH}/operations/${operation.id}`}
                    >
                      {operation.title}
                    </Link>
                  </td>
                  <td className="px-6 py-2 whitespace-normal break-words text-sm align-baseline">
                    {getShortDescription(operation.description)}
                  </td>

                  <td className="text-center whitespace-nowrap text-sm align-baseline">
                    {formatDate(operation.createdAt)}
                  </td>
                  <td className="text-center whitespace-nowrap text-sm align-baseline">
                    {formatDate(operation.updatedAt)}
                  </td>
                  <td className="text-center whitespace-nowrap text-sm align-baseline">
                    <Link
                      target="_blank"
                      className="cursor-pointer text-blue-500 hover:text-blue-700 underline mr-4"
                      to={`/opereta/${operation.slug}`}
                    >
                      Ver
                    </Link>
                    <Link
                      className="cursor-pointer text-blue-500 hover:text-blue-700 underline mr-4"
                      to={`/${ADMIN_PATH}/operations/${operation.id}`}
                    >
                      Editar
                    </Link>

                    <button
                      onClick={() => confirmDelete(operation.id)}
                      type="button"
                      className="cursor-pointer text-red-500 hover:text-red-700 underline"
                    >
                      Borrar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="flex justify-between mt-4">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className={`px-3 py-1 rounded-md ${currentPage === 1
                  ? "bg-gray-500 text-gray-300 cursor-not-allowed"
                  : "bg-gray-700 hover:bg-gray-600 text-gray-200 hover:text-gray-100"
                }`}
            >
              Previa
            </button>
            {!noMorePages && (
              <button
                onClick={handleNextPage}
                className="px-3 py-1 rounded-md bg-gray-700 hover:bg-gray-600 text-gray-200 hover:text-gray-100"
              >
                Siguiente
              </button>
            )}
          </div>
        </>
      )}
    </div>
  );
}
