import { useEffect, useState } from 'react';
import { OPERATIONS_URL } from '../../constants';
import { SmallLoading } from '../Loading';
import { ListCardAlt } from '../ListCardAlt/ListCard';

export function Recommendations({ id }) {
  const [loading, setLoading] = useState(true);
  const [recommendations, setRecommendations] = useState();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = `${OPERATIONS_URL}/recommendations/${id}`;
      const response = await fetch(url, { credentials: 'include' });

      if (response.status !== 200) {
        return;
      }

      const parsed = await response.json();
      setRecommendations(parsed);
      setLoading(false);
    };

    fetchData();
  }, [id]);

  if (loading) return <SmallLoading />;

  return (
    <div className="flex justify-center w-full">
      <div className="flex flex-col w-full">
        <h3 className="border-b-2	border-gray-800 mt-4 mb-1 w-full text-left text-2xl font-semibold text-gray-800">
          Otras Operetas Recomendadas
        </h3>
        {recommendations.map((record, idx) => (
          <ListCardAlt key={idx} data={record} />
        ))}
      </div>
    </div>
  );
}