import { MediaImage } from './MediaImage';
import { MediaTweet } from './MediaTweet';

export function CardMedia({ media }) {
  if (media.length === 1) return <MediaSwitcher media={media[0]} />

  return (
    <>
      <div className='flex items-center h-full w-full'>
        <div className='flex-1 justify-center flex flex-col gap-6' style={{ minWidth: '0' }}>
          {media.map((m, i) => <MediaSwitcher key={i} media={m} />)}
        </div>
      </div>
    </>
  )
}

function MediaSwitcher({ media }) {
  switch (media.type) {
    case 'image':
      return <MediaImage media={media} />
    case 'tweet':
      return <MediaTweet media={media} />
    default:
      return <></>
  }
}