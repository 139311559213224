import { Link, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
// import { Header } from '../components/Header';
import { ADMIN_PATH, SESSION_URL } from '../constants';
import { useEffect, useState } from 'react';
import { Loader } from '../components/Loading';

export function AdminLayout() {
  const [isLoadingSession, setLoadingSession] = useState(true);
  const [sessionError, setSessionError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(SESSION_URL, { credentials: 'include' });
      if (!response.ok) {
        setSessionError(true);
      }
      setLoadingSession(false)
    };
    fetchData();
  }, []);

  return (
    <div className="w-full flex flex-col min-h-screen">

      {/* <Header /> */}
      <div className="container flex mx-auto pb-4">
          <Toaster
            position="top-center"
            reverseOrder={false}
          />

        <section className='w-1/5 pt-6'>
          <div className="mt-1 flex flex-col gap-2 justify-start align-items-start">
            <div className="text-lg align-center">
              <Link className='hover:underline' to={'/'}>Web</Link>
            </div>
            <div className="text-lg align-center">
              <Link className='hover:underline' to={`/${ADMIN_PATH}`}>Operetas</Link>
            </div>
            <div className="text-lg align-center">
              <Link className='hover:underline' to={`/${ADMIN_PATH}/create`}>Nueva Opereta</Link>
            </div>
          </div>
        </section>

        <section className='w-4/5 body-font mx-auto'>
          {isLoadingSession ? <Loader /> : (sessionError ? 'Error' : <Outlet />)}
        </section>
      </div>
    </div>
  );
}
