export function Linkify({ children }) {
  const isUrl = word => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    return word.match(urlPattern)
  }

  const addMarkup = word => {
    return isUrl(word) ?
      `<a class="color-link" target="_blank" href="${word}">${word}</a>` :
      word
  }

  function formatTwitterUsernames(text) {
    const twitterUsernames = text.match(/@[a-zA-Z0-9_]+/g)
    if (twitterUsernames) {
      twitterUsernames.forEach(username => {
        text = text.replace(username, `<a target="_blank" class="color-link hover:underline" href="https://twitter.com/${username.substring(1)}">${username}</a>`);
      }
      )
    }
    return text;
  }

  if (!children) return <></>;
  const words = children.split(' ')
  const formatedWords = words.map((w, i) => addMarkup(w))
  const html = formatedWords.join(' ');
  const htmlWithTwitterUsernames = formatTwitterUsernames(html);
  return (<span className='whitespace-pre-line' dangerouslySetInnerHTML={{ __html: htmlWithTwitterUsernames }} />)
}