import { useState, useRef } from "react";
import { SmallLoading } from './Loading';

export function MultiSelectDropdown({
  formFieldName,
  options,
  onChange,
  loading,
  preSelected = [],
  prompt = "Seleccione una opción",
}) {
  const [selectedOptions, setSelectedOptions] = useState(preSelected);
  const optionsListRef = useRef(null);

  const handleChange = (e) => {
    const isChecked = e.target.checked;
    const option = e.target.value;

    const selectedOptionSet = new Set(selectedOptions);

    if (isChecked) {
      selectedOptionSet.add(option);
    } else {
      selectedOptionSet.delete(option);
    }

    const newSelectedOptions = Array.from(selectedOptionSet);

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const isClearSelectionEnabled = selectedOptions.length > 0;

  const handleClearSelectionClick = (e) => {
    e.preventDefault();

    const optionsInputs = optionsListRef.current.querySelectorAll("input");
    optionsInputs.forEach((input) => {
      input.checked = false;
    });

    setSelectedOptions([]);
    onChange([]);
  };

  return (
    <label className="relative">
      <input type="checkbox" className="hidden peer" />

      <div className="cursor-pointer after:content-['▼'] after:text-xs after:ml-1 after:inline-flex after:items-center peer-checked:after:-rotate-180 after:transition-transform inline-flex border rounded px-5 py-2">
        {prompt}
        {selectedOptions.length > 0 && (
          <span className="ml-1 text-blue-500">{`(${selectedOptions.length} ${selectedOptions.length === 1 ? 'seleccionado' : 'seleccionados'})`}</span>
        )}
      </div>

{/* TODO: dont set width and left for mobile */}
      <div className="multi-select-items-container absolute bg-white border transition-opacity opacity-0 pointer-events-none peer-checked:opacity-100 peer-checked:pointer-events-auto w-full max-h-60 overflow-y-scroll">
        <ul>
          <li>
            <button
              onClick={handleClearSelectionClick}
              disabled={!isClearSelectionEnabled}
              className="w-full text-right px-2 py-1 underline disabled:opacity-50"
            >
              {"Limpiar filtro"}
            </button>
          </li>
        </ul>
        {loading &&
          (<div className="w-full flex justify-center">

            <SmallLoading />
          </div>
          )
        }
        <ul ref={optionsListRef} className='grid grid-cols-1 md:grid-cols-2'>
          {options.map((option, i) => {
            return (
              <li key={option}>
                <label
                  className="flex items-baseline whitespace-nowrap cursor-pointer px-2 py-1 transition-colors hover:bg-blue-100 [&:has(input:checked)]:bg-blue-200"
                >
                  <input
                    type="checkbox"
                    name={formFieldName}
                    value={option}
                    className="cursor-pointer"
                    onChange={handleChange}
                    checked={selectedOptions.includes(option)}
                  />
                  <span className="ml-1 text-wrap">{option}</span>
                </label>
              </li>
            );
          })}
        </ul>
      </div>
    </label>
  );
}
