import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import { SEO_DESCRIPTION, SEO_KEYWORDS } from '../constants';

export function TermsAndConditions() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + (window.location.search || '') });
  }, []);

  return (
    <div className="bg-gray-100 mt-6 p-8 rounded-lg">
      <Helmet>
        <title>{'Operetas.com - Términos y condiciones'}</title>
        <meta name="description" content={SEO_DESCRIPTION} />
        <meta name="keywords" content={SEO_KEYWORDS} />

        <meta property="og:title" content={'Operetas.com - Términos y condiciones'} />
        <meta property="og:description" content={SEO_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.operetas.com" />
        <meta property="og:image" content="https://operetas.com/meta-logo.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={'Operetas.com - Términos y condiciones'} />
        <meta name="twitter:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:image" content="https://operetas.com/meta-logo.png" />
      </Helmet>
      <Link to="/" className='color-link'>Volver</Link>
      <h2 className="text-2xl font-bold mb-4">Términos y Condiciones</h2>
      <p className="mb-4">
        Bienvenido a nuestro sitio web. Al acceder y utilizar este sitio web, aceptas cumplir y estar sujeto a los siguientes términos y condiciones de uso. Si no estás de acuerdo con alguno de estos términos, por favor, no utilices este sitio. Los siguientes términos y condiciones se aplican a todos los visitantes, usuarios y otras personas que accedan o utilicen este sitio web.
      </p>
      <ol className="list-decimal ml-6 mb-4">
        <li className="mb-2">
          <span className="font-bold">Uso del Sitio:</span> Este sitio web y su contenido son propiedad y están operados por ciudadanos interesados en la verdad. El propósito principal de este sitio es proporcionar resúmenes de tweets públicos, junto con descripciones y contexto relevante para una mejor comprensión. Estos resúmenes son recopilados de fuentes públicas y se proporcionan con fines informativos únicamente.
        </li>
        <li className="mb-2">
          <span className="font-bold">Propiedad Intelectual:</span> Todo el contenido publicado en este sitio, incluyendo, pero no limitado a, textos, imágenes, gráficos, logotipos y software, está protegido por derechos de autor y otras leyes de propiedad intelectual. No puedes reproducir, distribuir, modificar, transmitir, reutilizar, descargar o utilizar de cualquier otra manera el contenido, excepto según lo permitido por estos términos o con el consentimiento previo por escrito del propietario de los derechos de autor.
        </li>
        <li className="mb-2">
          <span className="font-bold">Responsabilidad:</span> Hacemos todo lo posible para garantizar la exactitud y fiabilidad del contenido proporcionado en este sitio web. Sin embargo, no podemos garantizar la precisión, integridad o actualidad de la información. El uso de la información proporcionada en este sitio es bajo tu propio riesgo. No seremos responsables de ningún daño directo, indirecto, incidental, especial, consecuente o punitivo que resulte del uso o la imposibilidad de usar este sitio.
        </li>
        <li className="mb-2">
          <span className="font-bold">Modificaciones:</span> Nos reservamos el derecho de modificar o actualizar estos términos y condiciones en cualquier momento sin previo aviso. Se te recomienda revisar periódicamente esta página para estar al tanto de cualquier cambio. El uso continuado de este sitio después de la publicación de cambios constituye tu aceptación de dichos cambios.
        </li>
        <li className="mb-2">
          <span className="font-bold">Ley Aplicable:</span> Estos términos y condiciones se rigen e interpretan de acuerdo con las leyes de Argentina y cualquier disputa que surja en relación con estos términos y condiciones estará sujeta a la jurisdicción exclusiva de los tribunales de Calamuchita.
        </li>
      </ol>
      <p className="mt-4">Si tienes alguna pregunta sobre estos términos y condiciones, contáctanos en <a href="mailto:operetascom@gmail.com" className="color-link">operetascom@gmail.com</a>.</p>
    </div>
  );
};
