import { useEffect } from "react";
import { SEO_DESCRIPTION, SEO_KEYWORDS } from "../constants";
import { Loader } from "../components/Loading";
import { Helmet } from "react-helmet-async";
import ReactGA from 'react-ga4';
import { Tabs } from "../components/Tabs";
import { useGroupedOperations } from '../hooks/useGroupedOperations';
import { ListCardAlt } from '../components/ListCardAlt/ListCard';

const META_TITLE = "Operetas.com - Archivo de operetas políticas orwellianas";

export function OperetasByDate() {
  const { groupedOperations, loading } = useGroupedOperations();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + (window.location.search || '') });
  }, []);

  return (
    <div className="px-2 text-gray-600 body-font flex flex-col items-center">
      <Helmet>
        <title>{META_TITLE}</title>
        <meta name="description" content={SEO_DESCRIPTION} />
        <meta name="keywords" content={SEO_KEYWORDS} />

        <meta property="og:title" content={META_TITLE} />
        <meta property="og:description" content={SEO_DESCRIPTION} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.operetas.com" />
        <meta property="og:image" content="https://operetas.com/meta-logo.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={META_TITLE} />
        <meta name="twitter:description" content={SEO_DESCRIPTION} />
        <meta name="twitter:image" content="https://operetas.com/meta-logo.png" />
      </Helmet>
      <Tabs selected={'calendar'} />
      <div className="flex flex-col items-center justify-center w-full mx-auto mt-4">
        {!loading && groupedOperations.length === 0 && (
          <h1 className="text-xl mt-8">No hay operetas para ver</h1>
        )}
        {/* <div className="w-full flex flex-col items-end">
          <TagsFilter />
        </div> */}
        <div className="flex justify-center w-full">
          <div className="flex flex-col w-full">
            {groupedOperations.map((monthYear, idx) => (
              <MonthYearBlock key={idx} monthYear={monthYear.monthYear} items={monthYear.items} />
            ))}
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
}


function getMonthYearLabel(date) {
  const splittedDate = date.split('-');
  const month = splittedDate[0] - 1;
  const year = splittedDate[1];

  const monthNames = [
    "Enero", "Febrero", "Marzo",
    "Abril", "Mayo", "Junio", "Julio",
    "Agosto", "Septiembre", "Octubre",
    "Noviembre", "Diciembre"
  ];

  return `${monthNames[month]} ${year}`;

}

function MonthYearBlock({ monthYear, items }) {
  return (
    <div className="flex flex-col w-full mb-2">
      <div className="text-black flex border-b-2 border-gray-200 mb-2 mt-4 gap-2 justify-between sticky bg-white" style={{top: '69px', zIndex: '1'}}>
        {/* <h2 className="text-2xl font-semibold text-left">{getMonthYearLabel(monthYear)}</h2> */}
        <h3 className="text-lg text-right w-full"><span className='font-semibold'>{items.length}</span> {items.length > 1 ? 'operetas' : 'opereta'} en {getMonthYearLabel(monthYear)}</h3>
      </div>
      {items.map((record, idx) => (
        <ListCardAlt key={idx} data={record} />
        // <ItemCard key={idx} data={record} />
      ))}
    </div>
  );
}

// function ItemCard({ data }) {
//   return (
//     <div className="flex flex-col items-start w-full mt-2 mb-4 pb-2">
//     {/* <div className="flex flex-col items-start w-full mt-2 mb-6 border-b border-gray-100 pb-2"> */}
//       {/* <details className='w-full border-2 border-dashed border-stone-500 p-4 [&_svg]:open:-rotate-180'> */}
//       <details className='w-full border-stone-500 [&_svg]:open:-rotate-180'>
//         <summary className='cursor-pointer list-none items-start flex flex-col'>
//           <div className="flex w-full justify-between">
//             <div className='flex flex-col'>
//               <Link target='_blank' to={`/opereta/${data.slug}`}><h4 className='text-black font-semibold text-left text-lg mb-0 mt-0 '>{data.title}</h4></Link>
//               <h4 className='text-gray-400 text-left text-md'>{getDayMonthLabelInSpanish(data.startDate)}</h4>
//             </div>

//             <div style={{ marginTop: '4px' }}>
//               <svg className="rotate-0 transform text-blue-700 transition-all duration-300" fill="none" height="20" width="20" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24">
//                 <polyline points="6 9 12 15 18 9"></polyline>
//               </svg>
//             </div>
//           </div>

//           <div className="tags flex flex-wrap justify-start w-full mt-1">
//             {data.tags && <Tags tags={data.tags} />}
//           </div>
//           <Link className='color-link hover:underline mt-2 view-more-link' target='_blank' to={`/opereta/${data.slug}`}>Ver opereta</Link>
//         </summary>
//         <div className="flex flex-col w-full">

//           <div className="md:flex md:gap-1">
//             <div className={`flex flex-col gap-1 ${data.thumbnail && "md:w-4/6"}`}>
//               <Link target="_blank" to={`/opereta/${data.slug}`}>
//                 <p className="text-color-1 text-base cursor-pointer">
//                   <Linkify>{data.description}</Linkify>
//                 </p>
//               </Link>
//             </div>
//             {data.thumbnail && (
//               <div className="flex-0 flex items-center w-full mt-2 md:mt-0 md:w-2/6">
//                 <Link target="_blank" to={`/opereta/${data.slug}`}>
//                   <img alt="thumbnail" src={data.thumbnail} />
//                 </Link>
//               </div>
//             )}
//           </div>
//           <div className="flex flex-wrap justify-start w-full mt-4">
//             {data.tags && <Tags tags={data.tags} />}
//           </div>
//           <Link className='color-link hover:underline mt-2' target='_blank' to={`/opereta/${data.slug}`}>Ver opereta</Link>
//         </div>
//       </details>
//     </div>
//   );
// }