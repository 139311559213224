import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "./components/Login";

import { AdminCreateOperation } from "./components/admin/AdminCreateOperation";
import { OperetasList } from "./pages/OperetasList";
import { PageLayout } from "./layouts/PageLayout";
import { AdminListOperations } from "./components/admin/AdminListOperations";
import { AdminEditOperation } from "./components/admin/AdminEditOperation";
import { AdminLayout } from "./layouts/AdminLayout";
import { DetailCard } from "./components/DetailCard/DetailCard";
import { TermsAndConditions } from "./components/Tos";
import { PrivacyPolicy } from "./components/Privacy";
import { ADMIN_PATH } from './constants';
import ReactGA from 'react-ga4';
import { OperetasByDate } from './pages/OperetasByDate';
ReactGA.initialize('G-FBV89LVPZ4');

function App() {
  return (
    <Router>
      <Routes>
        <Route element={<AdminLayout />}>
          <Route path={`/${ADMIN_PATH}/login`} element={<Login />} />
          <Route path={`/${ADMIN_PATH}/create`} element={<AdminCreateOperation />} />
          <Route
            path={`/${ADMIN_PATH}/operations/:id`}
            element={<AdminEditOperation />}
          />
          <Route path={`/${ADMIN_PATH}`} element={<AdminListOperations />} />
        </Route>

        <Route element={<PageLayout />}>
          <Route path="/" element={<OperetasByDate />} />
          <Route path="/list" element={<OperetasList />} />
          <Route path="/opereta/:id" element={<DetailCard />} />
          <Route path="/tos" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
