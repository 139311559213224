/* API */
export const LOGIN_URL = `${process.env.REACT_APP_API_HOST}/login`;
export const ADMIN_OPERATIONS_URL = `${process.env.REACT_APP_API_HOST}/admin/operations`;
export const OPERATIONS_URL = `${process.env.REACT_APP_API_HOST}/operations`;
export const TAGS_URL = `${process.env.REACT_APP_API_HOST}/tags`;
export const SESSION_URL = `${process.env.REACT_APP_API_HOST}/session`;
export const GROUPED_OPERATIONS_URL = `${process.env.REACT_APP_API_HOST}/operations-grouped`;

export const ADMIN_PATH = "c49fc7de3e06ef416c0bc0e4da909dbd";
export const GO_TO_AFTER_LOGIN = `/${ADMIN_PATH}`;
export const GO_TO_AFTER_401 = `/${ADMIN_PATH}/login`;

export const SEO_DESCRIPTION = 'Archivo de Operetas mediáticas';
export const SEO_KEYWORDS = 'Operetas, Archivo, Milei, Izquierda, Batalla Cultural, Política, Argentina, Operetas mediáticas, Operetas políticas, Operetas de la izquierda, Operetas de la derecha, Operetas de Milei, Operetas de la política, Operetas de la batalla cultural, Operetas de la Argentina, George Orwell, Operaciones políticas, Operaciones mediáticas berretas, Operaciones mediáticas';
