import { useEffect, useState } from 'react';
import { GROUPED_OPERATIONS_URL } from '../constants';

export function useGroupedOperations() {
  const [groupedOperations, setGroupedOperations] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      
      const response = await fetch(GROUPED_OPERATIONS_URL, { credentials: 'include' });
      const data = await response.json();
      
      setGroupedOperations(data);
      setLoading(false);
    };

    fetchData();
  }, []);

  return { groupedOperations, loading };
}