import ModalImage from 'react-modal-image';
import { Linkify } from "../Linkify";
import { MediaFooter } from "./MediaFooter";

export function MediaImage({ media }) {
  const { url, preDescription, description, source } = media;

  return (
    <div className="media-card">
      <div className="flex flex-col">
        {preDescription && (
          <p className="mb-2 pt-2 text-lg text-color-4 text-base">
            <Linkify>{preDescription}</Linkify>
          </p>
        )}
        <div
          className="border-2 border-gray-200 w-full flex justify-center media-image-container"
          style={{ maxHeight: "550px" }}
        >
          <ModalImage
            small={url}
            large={url}
            className="h-auto w-auto object-contain mx-auto"
            hideDownload={true}
            hideZoom={true}
          />
          {/* <img
            alt="media"
            className="h-auto w-auto object-contain"
            src={url}
          ></img> */}
        </div>
        {description && (
          <p className="mt-2 pt-2 text-lg text-color-4 text-base">
            <Linkify>{description}</Linkify>
          </p>
        )}
        <MediaFooter source={source} align="left" />
      </div>
    </div>
  );
}
