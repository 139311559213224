import { useEffect, useState } from 'react';
import { TAGS_URL } from '../constants';

export function useTags() {
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTags() {
      const response = await fetch(TAGS_URL, { credentials: 'include' });
      const data = await response.json();
      setTags(data);
      setLoading(false);
    }
    fetchTags();
  }, []);

  return {
    tags,
    loading
  };
}