import React from "react";

export function Header() {
  return (
    <section className='w-full sticky top-0 header'>
      <div style={{ maxWidth: '1220px' }} className="md:container md:mx-auto flex flex-col">
        <div className="mt-1 flex px-2 py-2 justify-items-start w-full">
          <a href="/">
            <h1 className="logo text-color-4 text-5xl w-full flex-1 flex">
              <img src="/logo.png" alt="logo" className="w-12 h-12 mr-1" />
              <span>PERETAS</span>
            </h1>
          </a>
        </div>
      </div>
    </section>
  );
}
